import type { Account, ListItem, ListLight, SharePointList, SharePointSite, SiteLight } from '../types'
import toast from 'core/utils/toast'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { Input, selectStyles } from '../../shared/select-styles'
import { get, del, put } from 'core/services/http-service'
import type { MailCollectType, Provider } from 'components/oauth/types'
import { createId } from '@paralleldrive/cuid2'
import { DateTime } from 'luxon'
import { useLocation } from 'react-router'
import { setCookie } from 'components/oauth/cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder, faMagnifyingGlass, faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons'
import SmallLoader from 'components/shared/small-loader'
import { ReactComponent as Google } from '../../assets/sso/google.svg'
import { ReactComponent as Outlook } from '../../assets/outlook.svg'
import Modal from 'components/shared/modal'
import { z } from 'zod'

function ListChildren({ item }: { item: ListItem }) {
    return (
        <>
            <p className="flex gap-2 items-center">
                <FontAwesomeIcon icon={faFolder} className="text-atomic-tangerine h-4 w-4" />
                <span>{item.name}</span>
            </p>
            {item.children.length > 0 && (
                <div className="pl-4">
                    {item.children.map(children => (
                        <ListChildren key={children.id} item={children} />
                    ))}
                </div>
            )}
        </>
    )
}

function SharePointAdd({
    isSaving,
    account,
    onSelectedListChange,
    onSelectedSite,
    site,
}: {
    isSaving: boolean
    account: Account
    onSelectedListChange: (list: ListLight, checked: boolean) => void
    onSelectedSite: (site?: SiteLight) => void
    site?: SiteLight
}) {
    const { t } = useTranslation()

    const [loadingSite, setLoadingSite] = useState<boolean>(false)
    const [url, setUrl] = useState<string>()

    function updateSiteUrl(url: string) {
        const urlValidator = z.string().url()
        const result = urlValidator.safeParse(url)

        if (result.success) {
            setUrl(url)
        } else setUrl(undefined)
    }

    function selectList(list: ListLight, checked: boolean) {
        onSelectedListChange(list, checked)
    }

    async function loadSite() {
        setLoadingSite(true)
        onSelectedSite(undefined)

        const {
            data: { error, site },
        } = await get<
            { url: string },
            {
                data: { error: string; site: undefined } | { error: undefined; site: SiteLight }
            }
        >(`/v1/web/microsoft/accounts/${account.uuid}/sharepoint/site/infos`, {
            url,
        })

        setLoadingSite(false)

        if (error) {
            toast('error', error)

            return
        }

        onSelectedSite(site)
    }

    return (
        <div className="w-full relative">
            {isSaving && (
                <div className="absolute inset-0 z-20 flex items-center justify-center bg-white/50">
                    <div>
                        <SmallLoader />
                        <p>{t('web_me_options_sharepoint_saving')}</p>
                    </div>
                </div>
            )}
            <div className="flex gap-4 items-center">
                <input
                    type="url"
                    placeholder={t('web_me_options_sharepoint_input_label')}
                    className="grow w-full relative top-[1px]"
                    onChange={e => updateSiteUrl(e.currentTarget.value)}
                />
                <button
                    type="button"
                    className="flex gap-2 items-center btn"
                    onClick={async () => await loadSite()}
                    disabled={url?.length === 0 || !url || isSaving}
                >
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                    {t('web_dataroom_search')}
                </button>
            </div>
            {loadingSite ? (
                <div className="flex items-center flex-col">
                    <SmallLoader />
                    <span>{t('web_me_options_linked_mailbox_loading')}</span>
                </div>
            ) : (
                site && (
                    <div className="mt-8">
                        <p>{t('web_microsoft_sharepoint_help1', { site: site.name })}</p>
                        <p>{t('web_microsoft_sharepoint_help2')}</p>
                        <div className="flex flex-col gap-6 mt-4">
                            {site.lists.map(list => (
                                <div key={list.id} className="flex flex-col">
                                    <div className="flex gap-2 items-center">
                                        <input
                                            type="checkbox"
                                            id={`list-${list.id}`}
                                            value={list.id}
                                            onClick={e => selectList(list, e.currentTarget.checked)}
                                        />
                                        <label
                                            htmlFor={`list-${list.id}`}
                                            className="text-lg font-semibold cursor-pointer"
                                        >
                                            {list.name}
                                        </label>
                                    </div>
                                    {list.items.length > 0 && (
                                        <div className="pl-6">
                                            <p className="font-semibold">
                                                {t('web_microsoft_sharepoint_library_folders')}
                                            </p>
                                            {list.items.map(item => (
                                                <ListChildren key={item.id} item={item} />
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                )
            )}
        </div>
    )
}

export default SharePointAdd
