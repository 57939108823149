import toast from 'core/utils/toast'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { Input, selectStyles } from '../shared/select-styles'
import { get, del, put, post } from 'core/services/http-service'
import type { Account, ListLight, MailCollectType, Provider, SiteLight } from 'components/oauth/types'
import { createId } from '@paralleldrive/cuid2'
import { DateTime } from 'luxon'
import { useLocation } from 'react-router'
import { setCookie } from 'components/oauth/cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons'
import SmallLoader from 'components/shared/small-loader'
import { ReactComponent as Google } from '../../assets/sso/google.svg'
import { ReactComponent as Outlook } from '../../assets/outlook.svg'
import Modal from 'components/shared/modal'
import SharePointAdd from './microsoft/share-point-add'

function OauthAccounts() {
    const { t } = useTranslation()
    const location = useLocation()

    const accountCollectTypes = [
        { value: 'ALL', label: t('web_me_options_linked_mailbox_collect_type_ALL') },
        { value: 'MAIL_CONTENT', label: t('web_me_options_linked_mailbox_collect_type_MAIL_CONTENT') },
        { value: 'MAIL_ATTACHMENTS', label: t('web_me_options_linked_mailbox_collect_type_MAIL_ATTACHMENTS') },
    ] as const

    const [loading, setLoading] = useState<boolean>(true)
    const [providers, setProviders] = useState<Provider[]>([])
    const [accounts, setAccounts] = useState<Account[]>([])
    const [collectByAccount, setCollectByAccount] = useState<{ accountId: string; collectType: MailCollectType }[]>([])

    useEffect(() => {
        for (const account of accounts) {
            setCollectByAccount(prev => [...prev, { accountId: account.uuid, collectType: account.mailCollectType }])
        }
    }, [accounts])

    async function loadProviders() {
        setLoading(true)
        const {
            data: { providers },
        } = await get<void, { data: { providers: Provider[] } }>('/v1/web/oauth/providers')
        setProviders(providers)
        setLoading(false)
    }

    async function loadAccounts() {
        setLoading(true)
        const {
            data: { accounts },
        } = await get<void, { data: { accounts: Account[] } }>('/v1/web/oauth/accounts')
        setAccounts(accounts)
        setLoading(false)
    }

    useEffect(() => {
        loadProviders()
        loadAccounts()
    }, [])

    async function removeAccount(accountId: string) {
        const remove = confirm(t('web_me_options_unlink_mailbox_confirm'))
        if (remove) {
            const {
                data: { success, email },
            } = await del<{ data: { success: boolean; email: string } }>(`/v1/web/oauth/accounts/${accountId}`)

            if (success) {
                toast('success', t('web_me_options_mail_unlinked', { email }))
            } else {
                toast('error', t('web_me_options_linked_mailbox_error'))
            }

            await loadAccounts()
        }
    }

    async function accountCollectType(accountId: string, collectType: MailCollectType) {
        const putData = {
            collectType,
        }

        const {
            data: { success },
        } = await put<typeof putData, { data: { success: boolean } }>(
            `/v1/web/oauth/accounts/${accountId}/collect-type`,
            putData
        )

        if (success) {
            toast('success', t('web_me_options_linked_mailbox_collect_type_updated'))
            setCollectByAccount(prev =>
                prev.map(c => {
                    if (c.accountId === accountId) {
                        return { ...c, collectType }
                    }

                    return c
                })
            )
        } else {
            toast('error', t('web_me_options_linked_mailbox_error'))
        }
    }

    function oauthLogin(provider: Provider) {
        const state = createId()
        const expires = DateTime.local().plus({ minutes: 5 })

        setCookie(`${provider.reference}_oauth_state`, state, expires)
        setCookie(`${provider.reference}_oauth_redirect`, location.pathname, expires)

        const url = new URL(provider.loginUrl)
        url.searchParams.append('state', state)

        window.location.href = url.toString()
    }

    const [sharePointModal, showSharePointModal] = useState<boolean>(false)
    const [sharePointAccount, setSharePointAccount] = useState<Account>()
    const [sharePointSite, setSharePointSite] = useState<SiteLight>()
    const [sharePointLists, setSharePointLists] = useState<ListLight[]>([])

    function openSharePointModal(account: Account) {
        setSharePointAccount(account)
        setSharePointSite(undefined)
        setSharePointLists([])

        showSharePointModal(true)
    }

    function closeSharePointModal() {
        showSharePointModal(false)

        setSharePointAccount(undefined)
        setSharePointSite(undefined)
        setSharePointLists([])
    }

    function onSelectedListChange(list: ListLight, checked: boolean) {
        if (checked) {
            setSharePointLists([...sharePointLists, list])
        } else {
            setSharePointLists([...sharePointLists.filter(({ id }) => id !== list.id)])
        }
    }

    function onSelectedSite(site: SiteLight) {
        setSharePointSite(site)
        setSharePointLists([])
    }

    const [savingSharePoint, setSavingSharePoint] = useState<boolean>(false)

    async function saveSharePoint() {
        setSavingSharePoint(true)

        const postData = {
            site: sharePointSite.id,
            lists: sharePointLists.map(({ id }) => id),
        }

        const { data } = await post<
            typeof postData,
            {
                data:
                    | { success: true; email: string; message: undefined }
                    | { success: false; email: undefined; message?: string }
            }
        >(`/v1/web/microsoft/accounts/${sharePointAccount.uuid}/sharepoint/site`, postData)

        if (data.success) {
            await loadAccounts()
            closeSharePointModal()
            toast('success', t('web_me_options_sharepoint_saved'))
        } else {
            toast('error', t('web_me_options_linked_mailbox_error'))
            if (data.message) {
                toast('error', data.message, undefined, undefined, undefined, undefined, 5000)
            }
        }

        setSavingSharePoint(false)
    }

    return (
        <>
            <h2 className="font-bold mb-2 text-lg">{t('web_me_options_linked_mailbox')}</h2>
            <p className="mb-4">{t('web_me_options_linked_mailbox_help')}</p>

            {loading ? (
                <SmallLoader />
            ) : (
                accounts.length > 0 && (
                    <div className="my-2 mb-4 flex flex-col gap-4">
                        <Modal
                            size="1/2"
                            isOpen={sharePointModal}
                            onCancel={() => closeSharePointModal()}
                            onRequestClose={savingSharePoint ? undefined : () => closeSharePointModal()}
                            onConfirm={async () => await saveSharePoint()}
                            confirmDisabled={sharePointLists.length === 0 || savingSharePoint}
                            cancelDisabled={savingSharePoint}
                            okLabel={t('web_me_options_sharepoint_confirm_list_selection')}
                            title={t('web_me_options_sharepoint_add')}
                            cancelLabel={t('web_cancel')}
                            bottomAlignment="center"
                        >
                            <SharePointAdd
                                isSaving={savingSharePoint}
                                account={sharePointAccount}
                                onSelectedListChange={onSelectedListChange}
                                onSelectedSite={onSelectedSite}
                                site={sharePointSite}
                            />
                        </Modal>
                        {accounts.map(account => (
                            <div key={account.uuid} className="flex flex-col gap-2 rounded shadow-md p-4">
                                <div className="flex flex-col md:flex-row gap-1 md:gap-8 md:items-center w-full">
                                    <span className="shrink-0 flex items-center gap-2">
                                        {account.provider.reference === 'google' && <Google className="h-4 w-4" />}
                                        {account.provider.reference === 'microsoft' && <Outlook className="h-6 w-6" />}
                                        <span>{account.email}</span>
                                    </span>
                                    <span className="shrink-0">
                                        <span>{t('web_me_options_linked_mailbox_collect_type_label')}</span>
                                        <Select
                                            styles={selectStyles}
                                            components={{ Input }}
                                            placeholder={t('web_placeholder_select')}
                                            value={accountCollectTypes.find(
                                                opt =>
                                                    opt.value ===
                                                    collectByAccount.find(c => c.accountId === account.uuid)
                                                        ?.collectType
                                            )}
                                            options={accountCollectTypes}
                                            onChange={async opt =>
                                                await accountCollectType(account.uuid, opt.value as MailCollectType)
                                            }
                                        />
                                    </span>
                                    <button
                                        title={t('web_me_options_unlink_mailbox')}
                                        type="button"
                                        className="flex"
                                        onClick={async () => await removeAccount(account.uuid)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} className="text-bittersweet" />
                                    </button>
                                </div>
                                {account.provider.reference === 'microsoft' && (
                                    <div className="flex flex-col gap-2 pt-2">
                                        <button
                                            title={t('web_me_options_sharepoint_add')}
                                            type="button"
                                            className="flex items-center gap-2"
                                            onClick={() => openSharePointModal(account)}
                                        >
                                            <FontAwesomeIcon icon={faPlus} />
                                            {t('web_me_options_sharepoint_add')}
                                        </button>
                                        {account.sharePointSites.length > 0 && (
                                            <div className="flex flex-col gap-2">
                                                <p className="font-semibold">{t('web_me_options_added_sharepoints')}</p>
                                                {account.sharePointSites.map(site => (
                                                    <div key={site.id}>
                                                        <p>
                                                            {site.name} (
                                                            <a href={site.url} target="_blank" rel="noreferrer">
                                                                {site.url}
                                                            </a>
                                                            )
                                                        </p>
                                                        <div className="pl-4">
                                                            <p className="font-semibold">
                                                                {t('web_microsoft_sharepoint_libraries')}
                                                            </p>
                                                            <ul>
                                                                {site.lists.map(list => (
                                                                    <li key={list.id}>
                                                                        {list.name} (
                                                                        <a
                                                                            href={list.url}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            {list.url}
                                                                        </a>
                                                                        )
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )
            )}
            <div className="flex gap-4">
                {providers.map(provider => (
                    <button
                        className="flex items-center gap-2 btn"
                        type="button"
                        key={provider.reference}
                        onClick={() => oauthLogin(provider)}
                    >
                        {provider.reference === 'google' && <Google className="h-4 w-4" />}
                        {provider.reference === 'microsoft' && <Outlook className="h-6 w-6" />}
                        {t('web_me_options_link_mailbox', { provider: provider.name })}
                    </button>
                ))}
            </div>
        </>
    )
}

export default OauthAccounts
